import React from 'react';
import RouterPropTypes from 'react-router-prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { RateCompare, RateDetail, Rates } from 'pages';
import { useUser } from 'libs';

const RatesLayout = ({ location }) => {
  const user = useUser();
  return (
    <Switch>
      <Route path="/rates/:id(\d+)" component={RateDetail} />
      {user.features.show_compare && <Route exact path="/rates/compare" component={RateCompare} />}
      <Route exact path="/rates/lenders" component={Rates} />
      <Route exact path="/rates/lenders/:id(\d+)" component={Rates} />
      <Route exact path="/rates/products" component={Rates} />
      <Redirect exact from="/rates" to={{ pathname: '/rates/lenders', search: location.search }} />
      <Redirect to="/rates/lenders" />
    </Switch>
  );
};

RatesLayout.propTypes = {
  location: RouterPropTypes.location.isRequired,
};

export default withRouter(RatesLayout);
